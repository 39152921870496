<template>
  <a :href="url" rel="nofollow noopener noreferrer" target="_blank">{{ text ? text : url }}</a>
</template>

<script>
  export default
  {
    props: ['url', 'text']
  }
</script>
